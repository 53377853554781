<template>
  <div class="gry-bg py-5">
    <div class="profile">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6 col-md-8 mx-auto">
            
          
            <div class="bg-white rounded shadow-sm p-4 text-left">
                <h1 class="h3 fw-600">{{ $t('wang-ji-mi-ma') }}</h1>
                <p class="mb-4 opacity-60">{{ $t('forget-desc') }} </p>
                <form>
                  <div class="form-group">
                    <input type="email" v-model="form.email" class="form-control" value="" :placeholder="$t('dian-zi-you-jian')" name="email">
                  </div>
                  <div class="form-group text-right">
                    <button class="btn btn-primary btn-block" >
                      {{ $t('fa-song-mi-ma-zhong-zhi-lian-jie') }} </button>
                  </div>
                </form>
                <div class="mt-3">
                  <a @click="back" class="text-reset opacity-60">{{ $t('hui-dao-deng-ru') }}</a>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: '',
      }
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>